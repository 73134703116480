// toEven.js
'use strict';

export const TYPE_CEILING = 'ceiling';
export const TYPE_FLOOR = 'floor';

/**
 * Covert value to even number.
 * @param {number} {value} - value that convert to even number.
 * @param {'ceiling'|'floor'} {type} - use ceiling or floor to get closest even number.
 * @returns {number} return closest even number.
 */
const toEven = ({ value, type = TYPE_FLOOR }) => {
  const number = Number(value);

  if (Number.isNaN(number) || !Number.isFinite(number)) {
    throw new Error('error occured at resource/toEven.js');
  }

  if (number % 2 === 0) {
    return number;
  }

  if (Number.isInteger(number)) {
    return type === TYPE_FLOOR ? number - 1 : number + 1;
  }

  return 2 * Math.round(number / 2);
};

export default toEven;
