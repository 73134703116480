// HomeLiveStreamAd.js
import { connect } from 'react-redux';

import HomeLiveStreamAd from '../component/HomeLiveStreamAd.jsx';
import fetchOembedData from '../action/fetchOembedData.js';
import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getLivestreamAdUrl from '../selector/getLivestreamAdUrl.js';
import getMeData from '../selector/getMeData.js';
import { getIsInternalLink } from '../resource/getInternalLink.js';

const mapStateToProps = (state, { adType }) => {
  const meId = getMeData(state, 'id');
  const adURL = getLivestreamAdUrl(state, adType, meId);
  const isInternalLink = getIsInternalLink({
    link: adURL,
    whitelist: ['app.swag.live', 'swag.live'],
  });
  const oembedSelectPath = ['oembed', adURL];
  const adOembedData = getOperationData(state, ['oembed'], adURL);
  const isFetched = !!getNetworkingData(state, oembedSelectPath, 'isFetched');

  return {
    adURL,
    isInternalLink,
    isFetched,
    adOembedData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOembedData: ({ url, language, maximumWidth, maximumHeight }) =>
      dispatch(fetchOembedData({ url, language, maximumWidth, maximumHeight })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLiveStreamAd);
