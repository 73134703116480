// getLivestreamAdUrl.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import {
  FEATURE_LIVESTREAM_AD,
  FEATURE_LIVESTREAM_AD_2,
} from '../RemoteConfigKeys.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { livestreamAdType as livestreamAdTypeConstant } from '../resource/liveStreamConstants.js';

/**
 * check if url is valid
 * @param {string} {url} - url to be checked.
 * @return {boolean} return true if url is valid otherwise false.
 */
function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Select livestream ad url by ad type
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} adType - livestream ad type.
 * @param {string} meId - me id.
 * @return {string|undefined} The selected livestream ad url.
 */
const getLivestreamAdUrl = createCachedSelector(
  (state, adType) => adType,
  (state, adType, meId) =>
    getRemoteConfigData(state, FEATURE_LIVESTREAM_AD, undefined, {
      USER_ID: meId,
    }),
  (state, adType, meId) =>
    getRemoteConfigData(state, FEATURE_LIVESTREAM_AD_2, undefined, {
      USER_ID: meId,
    }),
  (adType, swaggerAdUrl, advertiserAdUrl) => {
    switch (adType) {
      case livestreamAdTypeConstant.SWAGGER: {
        if (!isValidUrl(swaggerAdUrl)) {
          return;
        }
        return swaggerAdUrl;
      }
      case livestreamAdTypeConstant.ADVERTISER: {
        if (!isValidUrl(advertiserAdUrl)) {
          return;
        }
        return advertiserAdUrl;
      }
      default:
        return;
    }
  }
)((state, adType, meId) => `${adType}:${meId}`);

export default getLivestreamAdUrl;
